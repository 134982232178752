/* eslint-disable */
import { React, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import { FormattedMessage, injectIntl } from 'react-intl'
import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'
import { Flex, Box } from '../../common/Grid'

import Container from '../../common/Container'

import Image from '../../common/Image'

import Button from '../../common/Button'

import sendEvent from '../../../helpers/sendAnalytics'

const BlueContainer = styled.div`
  ${tw`bg-light-blue`}
`

const Description = styled.div`
  ${tw`mt-8 mb-8 font-raleway font-medium text-lg leading-normal text-blog-text`}
  max-width: 100%;
  width: 100%;

  p {
    margin: 0px;
    max-width: 100%;
    width: 100%;
    font-family: 'Raleway', 'Lucida Grande', Tahoma, sans-serif;
  }

  h2 {
    ${tw`font-light`}
  }

  a.coupon {
    ${tw`outline-none font-montserrat text-sm text-center no-underline cursor-pointer font-semibold text-white mt-8 -mb-10`}
    padding: 15px 30px;
    border: 1px solid #3475b7;
    background-color: #3475b7;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    line-height: 1.258;
    width: auto;
    color: '#fff';
    border-radius: 9999px;
    display: inline-block;

    &:hover,
    &:focus {
      background-color: #6db3f8;
      border-color: #6db3f8;
      color: #fff;
      ${tw`shadow-outline`}
    }
  }
`

const LogoImg = styled(Image)`
  @media screen and (min-width: 64em) {
    width: 240px;
    height: auto;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 64em) {
    max-width: 200px;
    height: auto;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 45em) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
  }
`

const LeftBox = styled(Box)``

const RightBox = styled(Box)`
  img {
    width: 100%;
  }

  &.rightBox-bbca9194-963a-4ad3-b701-80387cb652ba img,
  &.rightBox-53274db6-d39c-40c4-bed5-40b1c6a7af56 img {
    width: 350px;
    height: auto;
    margin: 0 calc(50% - 175px);
  }
`

const ProductCategoryBlockWrapper = styled.section`
  ${tw`bg-light-blue py-16`}
  display: block !important;
  @media screen and (max-width: 64em) {
    ${tw`py-8`}
  }

  .product-category-image,
  .mobile-image {
    display: none;
  }

  @media (max-width: 1023px) {
    .mobile-image {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
    .pimage {
      display: none;
    }
  }

  .anchor-target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
`

const ProductCategoryBlock = props => {
  let { modification, title, video, logo_image, guid, intl } = props

  let { description, product_category_image } = props

  let reviewId = ''
  let BVtitle = title
  let productType = ''
  let reviewUrl = intl.locale === 'fr' ? '../../../fr/avis' : '../../../reviews'
  let gtagTracking = 'AW-951001886/OHJYCIWSmf0YEJ7GvMUD'
  let anchorId = ''

  let miq = ''
  let ga = ''

  // Helper function to send event based on locale
  function sendCouponClickEvent(productName) {
    const eventLabel =
      intl.locale === 'fr'
        ? `${productName}_Coupon_Click_FR`
        : `${productName}_Coupon_Click`
    sendEvent({
      ga: { category: 'Coupon_Click', action: 'Click', label: eventLabel },
    })
  }

  // Helper function to get product category image based on locale
  function getProductCategoryImage(enImage, frImage) {
    return intl.locale === 'fr' ? frImage : enImage
  }

  // Helper function to get reviewId based on locale
  function getReviewId(enId, frId) {
    return intl.locale === 'fr' ? frId : enId
  }

  // Helper function to get BV title based on locale
  function getBVTitle(enTitle, frTitle) {
    return intl.locale === 'fr' ? frTitle : enTitle
  }

  // Main switch statement
  switch (guid) {
    case '8b06378d-e705-4376-9e19-99ff1990884b':
      productType = 'RVUP'
      sendCouponClickEvent('RVUP')
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/bt-plush2-fr.jpg'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/bt-plush2-en.webp'
      logo_image =
        'https://img.imageboss.me/royale-images/width/550/format:webp/images/up_logo_image.webp'
      description = description.replace(
        'class="coupon"',
        `class="ultraplus-coupon coupon"`
      )
      anchorId = 'velour-ultra-section'
      reviewId = getReviewId('vupr_master_en', 'vupr_master_fr')
      BVtitle = 'Ultra Plush'
      break

    case '7828b95f-2724-4e2c-b389-631e42955091':
      productType = 'BATHROOMTISSUE'
      miq =
        "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);"
      sendCouponClickEvent('Velour')
      product_category_image =
        'https://img.imageboss.me/royale-images/width/630/format:webp/images/Bath_Web_Velour_795x450px.jpg'
      logo_image =
        'https://img.imageboss.me/royale-images/width/550/format:webp/images/velour_logo.webp'
      anchorId = 'velour-section'
      reviewId = getReviewId(
        'VelourBathroomTissue-EN',
        'VelourBathroomTissue-FR'
      )
      BVtitle = 'Velour'
      break

    case 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae':
      productType = 'TIGERTOWEL'
      gtagTracking = 'AW-951001886/VQbQCN2gm_0YEJ7GvMUD'
      miq =
        "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);"
      miq +=
        "var a2img=document.createElement('img');a2img.style.width='1px';a2img.style.height='1px';a2img.src='https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:yotvgnb&fmt=3';document.body.appendChild(a2img);"
      sendCouponClickEvent('Tiger_Towel')
      reviewId = getReviewId('TigerTowel-EN', 'TigerTowel-FR')
      BVtitle = 'Tiger Towel'
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/rtt_2024_fr.jpg'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/rtt_2024_en.jpg'
      logo_image =
        'https://img.imageboss.me/royale-images/width/550/format:webp/images/royale_tigertowel_logo-tmp.webp'
      break

    case '121a0dec-b670-47f7-801a-970a945fd1e1':
    case '2a5a3a6b-6206-42ab-b752-1da88e52dcad':
      productType = 'FACIALTISSUE'
      gtagTracking = 'AW-951001886/GG8WCO7klv0YEJ7GvMUD'
      miq =
        "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);"
      sendCouponClickEvent('Facial')
      reviewId = getReviewId(
        'OriginalFacialTissue-EN',
        'OriginalFacialTissue-FR'
      )
      BVtitle = getBVTitle(
        'Royale® Original 2-Ply Facial Tissue',
        'Mouchoirs Royale® Original à 2 épaisseurs'
      )
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/FacialCubes-Web-ProductPage-FR.png'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/FacialCubes-Web-ProductPage-EN.png'
      break

    case 'bbca9194-963a-4ad3-b701-80387cb652ba':
      productType = 'FACIALTISSUE'
      gtagTracking = 'AW-951001886/GG8WCO7klv0YEJ7GvMUD'
      miq =
        "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);"
      sendCouponClickEvent('Facial')
      reviewId = getReviewId(
        'OriginalFacialTissue-EN',
        'OriginalFacialTissue-FR'
      )
      BVtitle = getBVTitle(
        'Royale® Original 2-Ply Facial Tissue',
        'Mouchoirs Royale® Original à 2 épaisseurs'
      )
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/Original-2024-FR.png'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/Original-2024-EN.png'
      break

    case '53274db6-d39c-40c4-bed5-40b1c6a7af56':
      productType = 'FACIALTISSUE'
      gtagTracking = 'AW-951001886/GG8WCO7klv0YEJ7GvMUD'
      miq =
        "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);"
      sendCouponClickEvent('Facial')
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/Velour-2024-FR.png'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/Velour-2024-EN.png'
      reviewId = getReviewId('VelourFacialTissue-EN', 'VelourFacialTissue-FR')
      BVtitle = getBVTitle(
        'Royale® Velour™ 3-Ply Facial Tissue',
        'Mouchoirs à 3 épaisseurs Royale® VelourMC'
      )
      break

    case 'c0426664-78c9-42b8-be11-0282938a8db3':
      product_category_image =
        'https://img.imageboss.me/royale-images/width/630/format:webp/images/Bath_Web_Original_795x450px.jpg'
      logo_image =
        'https://img.imageboss.me/royale-images/width/550/format:webp/images/OriginalLogo.webp'
      anchorId = 'original-section'
      reviewId = getReviewId(
        'OriginalBathroomTissue-EN',
        'OriginalBathroomTissue-FR'
      )
      BVtitle = 'Original'
      break

    case '09e9f205-4c9d-4fc6-9628-c193e498d628':
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/bt-3ply2-fr.jpg'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/bt-3ply2-en.webp'
      logo_image =
        'https://img.imageboss.me/royale-images/width/550/format:webp/images/3ply-new-logo-tmp.webp'
      anchorId = 'threeply-section'
      reviewId = getReviewId('3PlyBathroomTissue-EN', '3PlyBathroomTissue-FR')
      BVtitle = '3-Ply Strong'
      break

    case 'c0355a4f-6856-47fb-9b90-5555a3859fad':
      product_category_image =
        intl.locale === 'fr'
          ? 'https://img.imageboss.me/royale-images/width/630/format:webp/images/npk-packshot-fr.png'
          : 'https://img.imageboss.me/royale-images/width/630/format:webp/images/npk-packshot-en.webp'
      gtagTracking = 'AW-951001886/oMIeCIXFmf0YEJ7GvMUD'
      reviewId = getReviewId('Napkins-EN', 'Napkins-FR')
      BVtitle = getBVTitle('ROYALE® Napkins', 'Serviettes de table ROYALE®')
      break

    default:
  }

  description = description.replace(
    'class="coupon"',
    `class="coupon" onClick="fbq('track','Purchase');gtag('event','conversion',{'send_to':'AW-951001886/S9_KCIL27pIBEJ7GvMUD'});${miq}${ga}"`
  )

  if (productType === 'FACIALTISSUE') {
    if (
      guid != '121a0dec-b670-47f7-801a-970a945fd1e1' &&
      guid != '2a5a3a6b-6206-42ab-b752-1da88e52dcad'
    ) {
      let text = intl.locale === 'fr' ? 'Écrire un avis' : 'Write a review'
      let reviewCode =
        '<a style="text-decoration: none" href="' +
        reviewUrl +
        '?bvid=' +
        reviewId +
        '&title=' +
        BVtitle +
        '" target="_blank"><div id="rating-summary-' +
        guid +
        '" data-bv-show="inline_rating" data-bv-product-id="' +
        reviewId +
        '"></div><small style="margin-bottom: 15px; display: block" class="normal">' +
        text +
        '</small></a>'
      description = description.replace(
        '</h2>\n<p>',
        '</h2>\n ' + reviewCode + ' </p>'
      )
    }
  }

  const [showReviews, setShowReviews] = useState(false)

  return (
    <ProductCategoryBlockWrapper
      id={guid}
      data-guid={guid}
      name={anchorId}
      className={anchorId}
      modification={modification}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
      <a
        id={anchorId}
        name={anchorId}
        aria-hidden="true"
        className="anchor-target"
        href="#"
      />
      {(product_category_image &&
        guid !== '8b06378d-e705-4376-9e19-99ff1990884b') ||
      guid !== 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
      guid !== '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
      guid !== '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ? (
        <Image
          url={product_category_image}
          alt="Royale"
          className="product-category-image"
          sizes="100vw"
        />
      ) : null}

      {guid === 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
      guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
      guid === '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ||
      guid === '121a0dec-b670-47f7-801a-970a945fd1e1' ? (
        <img
          src={product_category_image}
          className="mobile-image"
          alt={'Strong Royale'}
        />
      ) : null}

      <Container modification={modification}>
        {logo_image ? (
          <LogoImg
            url={logo_image}
            sizes="(min-width: 64em) 525px, 100vw"
            alt={striptags(title)}
          />
        ) : null}
      </Container>

      <IsDesktop>
        <BlueContainer
          video={
            video && guid !== 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae'
              ? 'yes'
              : 'no'
          }
        >
          <Container className="rvup-container" id={'rvup' + guid} width={1060}>
            {productType !== 'FACIALTISSUE' ? (
              <a
                style={{ 'text-decoration': 'none' }}
                href={`${reviewUrl}?bvid=${reviewId}&title=${BVtitle}`}
                target="_blank"
              >
                <div
                  id={'rating-summary-' + guid}
                  data-bv-show="inline_rating"
                  data-bv-product-id={reviewId}
                />
                <small className="normal">
                  {intl.locale === 'fr' ? 'Écrire un avis' : 'Write a review'}
                </small>
              </a>
            ) : null}
            <Flex
              alignItems={
                guid == 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
                guid == '53274db6-d39c-40c4-bed5-40b1c6a7af56'
                  ? 'center'
                  : 'flex-start'
              }
            >
              <LeftBox className={`leftb-${guid}`} width={1 / 2} pr={6}>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Button
                  to="ee65cb8e-5cac-4013-bc5c-31d075d0ab89"
                  onClick={() => sendEvent({ gtag: { send_to: gtagTracking } })}
                  title="Buy Online"
                  style={{ marginRight: 'auto' }}
                >
                  <FormattedMessage id="buy_online" />
                </Button>
              </LeftBox>

              {guid === '8b06378d-e705-4376-9e19-99ff1990884b' ||
              guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ||
              guid === '121a0dec-b670-47f7-801a-970a945fd1e1' ||
              guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
              guid === 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
              guid === 'c0426664-78c9-42b8-be11-0282938a8db3' ||
              guid === 'c0355a4f-6856-47fb-9b90-5555a3859fad' ||
              guid === '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ? (
                <RightBox width={1 / 2}>
                  <img src={product_category_image} alt={'Strong Royale'} />
                </RightBox>
              ) : (
                <RightBox className={'rightBox-' + guid} width={1 / 2}>
                  {product_category_image ? (
                    <Image
                      url={product_category_image}
                      sizes={
                        guid === '8b06378d-e705-4376-9e19-99ff1990884b'
                          ? '(min-width: 64em) 50vw, 100vw'
                          : '(min-width: 64em) 530px, 100vw'
                      }
                    />
                  ) : null}
                </RightBox>
              )}
            </Flex>
            {guid == 'c0426664-78c9-42b8-be11-0282938a8db3' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/original-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/original-en.png'
                  }
                  alt="Original Pack"
                />
                <FormattedMessage id="paper.product1">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
            {guid == '7828b95f-2724-4e2c-b389-631e42955091' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/velour-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/velour-en.png'
                  }
                  alt="Velour Pack"
                />
                <FormattedMessage id="paper.product2">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
            {guid == 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/tt-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/tt-en.png'
                  }
                  alt="Tiger Towel Pack"
                />
                <FormattedMessage id="paper.product3">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
          </Container>
        </BlueContainer>
      </IsDesktop>

      <IsMobile>
        <BlueContainer
          className={`bluemobile-${guid}`}
          video={video ? 'yes' : 'no'}
        >
          <Container width={1060} id={'rvup' + guid}>
            {productType !== 'FACIALTISSUE' ? (
              <a
                style={{ 'text-decoration': 'none' }}
                href={`${reviewUrl}?bvid=${reviewId}&title=${BVtitle}`}
                target="_blank"
              >
                <div
                  id={'rating-summary-' + guid}
                  data-bv-show="inline_rating"
                  data-bv-product-id={reviewId}
                />
                <small className="normal">
                  {intl.locale === 'fr' ? 'Écrire un avis' : 'Write a review'}
                </small>
              </a>
            ) : null}
            <Flex>
              <Box>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Button
                  to="ee65cb8e-5cac-4013-bc5c-31d075d0ab89"
                  onClick={() => sendEvent({ gtag: { send_to: gtagTracking } })}
                  title="Buy Online"
                  style={{ marginRight: 'auto' }}
                >
                  <FormattedMessage id="buy_online" />
                </Button>
                {guid === '8b06378d-e705-4376-9e19-99ff1990884b' ||
                guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ? (
                  <div className="pimage">
                    {guid === '8b06378d-e705-4376-9e19-99ff1990884b' ? (
                      intl.locale === 'fr' ? (
                        <img src={product_category_image} alt="Strong Royale" />
                      ) : (
                        <img src={product_category_image} alt="Strong Royale" />
                      )
                    ) : null}
                    {guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ? (
                      intl.locale === 'fr' ? (
                        <img src={product_category_image} alt="Strong Royale" />
                      ) : (
                        <img src={product_category_image} alt="Strong Royale" />
                      )
                    ) : null}
                  </div>
                ) : (
                  <div className="pimage">
                    {product_category_image ? (
                      <Image
                        url={product_category_image}
                        sizes={
                          guid === '8b06378d-e705-4376-9e19-99ff1990884b'
                            ? '(min-width: 64em) 50vw, 100vw'
                            : '(min-width: 64em) 530px, 100vw'
                        }
                      />
                    ) : null}
                  </div>
                )}
              </Box>
            </Flex>
            {guid == 'c0426664-78c9-42b8-be11-0282938a8db3' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/original-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/original-en.png'
                  }
                  alt="Original Pack"
                />
                <FormattedMessage id="paper.product1">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
            {guid == '7828b95f-2724-4e2c-b389-631e42955091' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/velour-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/velour-en.png'
                  }
                  alt="Velour Pack"
                />
                <FormattedMessage id="paper.product2">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
            {guid == 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ? (
              <div className="product-low">
                <img
                  src={
                    intl.locale === 'fr'
                      ? 'https://img.imageboss.me/royale-images/width/300/format:webp/images/tt-fr.png'
                      : 'https://img.imageboss.me/royale-images/width/300/format:webp/images/tt-en.png'
                  }
                  alt="Tiger Towel Pack"
                />
                <FormattedMessage id="paper.product3">
                  {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            ) : null}
          </Container>
        </BlueContainer>
      </IsMobile>
    </ProductCategoryBlockWrapper>
  )
}

export const productCategoryBlockFragment = graphql`
  fragment productCategoryBlockFragment on ProductCategoryBlock {
    guid
    id
    modification
    description
    video
    product_header
    logo_image_url
    product_category_image_url
    products {
      id
      title
      title_html
      guid
      images_url
    }
  }
`

export default injectIntl(ProductCategoryBlock)
